<template>
  <v-col cols="12">
    <!-- <pre>{{ preferences_1 }}</pre> -->
    <v-text-field
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="() => (searchValue = '')"
    />

    <v-tabs
      v-model="tab"
      class="my-2"
    >
      <v-tab>Сборы за ТО</v-tab>
      <v-tab>Таможенная пошлина</v-tab>
      <v-tab>Акцизы</v-tab>
      <v-tab>НДС</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredPreferences_1"
              :key="'preferences_1-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredPreferences_2"
              :key="'preferences_2-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredPreferences_3"
              :key="'preferences_3-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredPreferences_4"
              :key="'preferences_4-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>
<script>
// import { mapGetters } from "vuex";

export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
  },
  data: () => ({
    tab: 0,
    searchValue: "",
    // 
    preferences_1: [],
    preferences_2: [],
    preferences_3: [],
    preferences_4: [],
  }),
  computed: {
    filteredPreferences_1(){
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 0
      ) {
        return this.preferences_1.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.preferences_1;
    },
    filteredPreferences_2(){
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 1
      ) {
        return this.preferences_2.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.preferences_2;
    },
    filteredPreferences_3(){
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 2
      ) {
        return this.preferences_3.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.preferences_3;
    },
    filteredPreferences_4(){
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 3
      ) {
        return this.preferences_4.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.preferences_4;
    },
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('catalogs/fetchNsiPreferences')
      .then(({data}) => {
        this.preferences_1 = data.list.filter(i => i.type === 1).map((i) => { return {...i, search: `${i.code}-${i.name}`.toLowerCase() }})
        this.preferences_2 = data.list.filter(i => i.type === 2).map((i) => { return {...i, search: `${i.code}-${i.name}`.toLowerCase() }})
        this.preferences_3 = data.list.filter(i => i.type === 3).map((i) => { return {...i, search: `${i.code}-${i.name}`.toLowerCase() }})
        this.preferences_4 = data.list.filter(i => i.type === 4).map((i) => { return {...i, search: `${i.code}-${i.name}`.toLowerCase() }})
      })
  },
  methods: {
    select(code) {
      let catalog = null
      switch(this.tab){
      case 0: catalog = 'preference_1'; ++this.tab; break;
      case 1: catalog = 'preference_2'; ++this.tab;break;
      case 2: catalog = 'preference_3'; ++this.tab;break;
      case 3: catalog = 'preference_4'; this.tab = 0;break;
      }
      this.$emit("select", {code, catalog});
    },
  },
};
</script>