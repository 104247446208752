<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="sd-dealtypes-viewer">
        <preferences @select="onSelect" />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import Preferences from "@/components/catalogs/preferences.vue"
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {catalogValueSelected} from '@/events/statistics'
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
export default {
  components: {
    Preferences
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  methods:{
    onSelect({code,catalog}){
      catalogValueSelected.trigger({code,catalog, field:this.field}, this.field)
      if(catalog === "preference_4"){
        this.close()
      }
    }
  }
};
</script>
<style scoped>
.sd-dealtypes-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
